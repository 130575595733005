<template>
  <div class="text-center my-auto">
    <div class="font-bold text-3xl">{{ total }}</div>
    <div :class="uppercase == true && 'uppercase'">{{ title }}</div>
    <div>{{ caption }}</div>
  </div>
</template>

<script>
import FeatherIcon from '@/components/FeatherIcon.vue';

export default {
  name: 'StatisticInfo',
  components: {
    FeatherIcon,
  },
  props: {
    total: {
      type: String,
      default: '0',
    },
    title: {
      type: String,
      default: '',
    },
    caption: {
      type: String,
      default: '',
    },
    uppercase: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
