<template>
  <div class="text-center my-auto">
    <div
      v-if="percentage"
      :class="isMinus == true ? 'text-danger' : 'text-success'"
    >
      <i
        class="fas font-weight-light mr-2"
        :class="isMinus == true ? 'fa-arrow-down' : 'fa-arrow-up'"
      ></i>
      {{ percentage + '%' }}
    </div>
    <div class="font-bold">{{ total }}</div>
    <div class="uppercase">{{ title }}</div>
  </div>
</template>

<script>
import FeatherIcon from '@/components/FeatherIcon.vue';

export default {
  name: 'StatisticPercentage',
  components: {
    FeatherIcon,
  },
  props: {
    percentage: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    isMinus: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
