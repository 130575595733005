<template>
  <vx-card>
    <div class="mb-6 text-5xl text-black">Promo Dashboard</div>
    <div class="vx-col w-full mb-base space-y-8">
      <!-- Filters -->
      <div class="space-y-4">
        <div class="w-full flex flex-row items-center gap-4">
          <div class="vx-col">
            <span>Region</span>
            <multiselect
              class="selectExample"
              v-model="selectedRegions"
              :options="optionRegions"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              :custom-label="customLabel"
            >
            </multiselect>
          </div>
          <div class="vx-col">
            <span>Operating Unit</span>
            <multiselect
              class="selectExample"
              v-model="selectedOUs"
              :options="optionOUs"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              :custom-label="customLabel"
            >
            </multiselect>
          </div>
          <div class="vx-col">
            <span>Territory</span>
            <multiselect
              class="selectExample"
              v-model="selectedTerritories"
              :options="optionTerritories"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              :custom-label="customLabel"
            >
            </multiselect>
          </div>
        </div>
        <div class="w-full flex flex-row items-center gap-4">
          <div class="vx-col">
            <span>Promotion Type</span>
            <multiselect
              class="selectExample"
              v-model="selectedPromotionTypes"
              :options="optionPromotionTypes"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              :custom-label="customLabel"
            >
            </multiselect>
          </div>
          <div class="vx-col">
            <span>Ownership</span>
            <multiselect
              class="selectExample"
              v-model="selectedOwnerships"
              :options="optionOwnerships"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              :custom-label="customLabel"
            >
            </multiselect>
          </div>
          <div class="vx-col">
            <span>Budget Code</span>
            <multiselect
              class="selectExample"
              v-model="selectedBudgetCodes"
              :options="optionBudgetCodes"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              :custom-label="customLabel"
            >
            </multiselect>
          </div>
        </div>
      </div>
      <!-- Charts -->
      <div class="w-full flex flex-row items-center justify-between">
        <PieChart
          :chartData="chartDataRealisasiBudget"
          title="Realisasi Budget"
        />
        <PieChart :chartData="chartDataFreegood" title="Freegood vs Discount" />
        <PieChart
          :chartData="chartDataOwnership"
          title="Ownership Realization"
        />
      </div>
      <!-- Percentage Charts -->
      <div class="space-y-8 mt-8">
        <div class="w-full grid grid-cols-3">
          <StatisticPercentage
            :percentage="this.total_target.percentage"
            :total="this.total_target.total"
            title="Total Target"
            :isMinus="this.total_target.percentage < 0"
          />
          <StatisticPercentage
            :percentage="this.goal_completion.percentage"
            :total="this.goal_completion.total"
            title="Goal Completion"
            :isMinus="this.goal_completion.percentage < 0"
          />
          <div
            class="border-l border-slate-100 border-t-0 border-b-0 border-r-0 border-solid"
          >
            <StatisticInfo
              :total="this.ongoing_target"
              title="Ongoing Target"
            />
          </div>
        </div>
        <div class="w-full grid grid-cols-3">
          <StatisticPercentage
            :percentage="this.special_total_target.percentage"
            :total="this.special_total_target.total"
            title="Special Total Target"
            :isMinus="this.special_total_target.percentage < 0"
          />
          <StatisticPercentage
            :percentage="this.special_goal_completion.percentage"
            :total="this.special_goal_completion.total"
            title="Special Goal Completion"
            :isMinus="this.special_goal_completion.percentage < 0"
          />
          <div
            class="border-l border-slate-100 border-t-0 border-b-0 border-r-0 border-solid"
          >
            <StatisticInfo
              :total="this.special_ongoing_target"
              title="Special Ongoing Target"
            />
          </div>
        </div>
      </div>
    </div>
  </vx-card>
</template>
<script>
import PieChart from '@/components/charts/PieChart.vue';
import StatisticPercentage from '@/components/statistic-info/StatisticPercentage.vue';
import StatisticInfo from '@/components/statistic-info/StatisticInfo.vue';

export default {
  components: {
    PieChart,
    StatisticPercentage,
    StatisticInfo,
  },
  data() {
    return {
      chartDataRealisasiBudget: {
        labels: ['Budget Used Value', 'Remaining Unused Budget'],
        datasets: [
          {
            backgroundColor: ['#f4bc36', '#fb9c44', '#598edb'],
            data: [50, 50],
          },
        ],
      },
      chartDataFreegood: {
        labels: ['Freegood', 'Discount'],
        datasets: [
          {
            backgroundColor: ['#f4bc36', '#fb9c44', '#598edb'],
            data: [55, 45],
          },
        ],
      },
      chartDataOwnership: {
        labels: ['Principal', 'Internal', 'Mix'],
        datasets: [
          {
            backgroundColor: ['#f4bc36', '#fb9c44', '#598edb'],
            data: [35, 30, 35],
          },
        ],
      },
      baseUrl: '/api/report/v1/territories',
      selectedTerritories: [],
      optionTerritories: [],
      territoryIDs: [],
      territoryCodes: [],
      territoryNames: [],
      draw: 0,
      total_target: {
        total: 0,
        percentage: 0,
      },
      goal_completion: {
        total: 0,
        percentage: 0,
      },
      ongoing_target: 0,
      special_total_target: {
        total: 0,
        percentage: 0,
      },
      special_goal_completion: {
        total: 0,
        percentage: 0,
      },
      special_ongoing_target: 0,
      selectedRegions: [],
      optionRegions: [],
      selectedOUs: [],
      optionOUs: [],
      selectedPromotionTypes: [],
      optionPromotionTypes: [],
      selectedOwnerships: [],
      optionOwnerships: [],
      selectedBudgetCodes: [],
      optionBudgetCodes: [],
    };
  },
  methods: {
    customLabel(val) {
      if (val) {
        return val.id === '-' ? `${val.name}` : `(${val.id}) ${val.name}`;
      }
    },
    getDashboardData() {
      this.$http.get('/api/report/v1/dashboard/promo').then((resp) => {
        this.chartDataRealisasiBudget.datasets[0].data = [
          resp.data.Records.budget_realization.find((x) => {
            return x.label == 'budget_used_value';
          }).value,
          resp.data.Records.budget_realization.find((x) => {
            return x.label == 'remaining_unused_budget';
          }).value,
        ];
        this.chartDataFreegood.datasets[0].data = [
          resp.data.Records.free_good_vs_discount.find((x) => {
            return x.label == 'freegood';
          }).value,
          resp.data.Records.free_good_vs_discount.find((x) => {
            return x.label == 'discount';
          }).value,
        ];
        this.chartDataOwnership.datasets[0].data = [
          resp.data.Records.ownership_realization.find((x) => {
            return x.label == 'principal';
          }).value,
          resp.data.Records.ownership_realization.find((x) => {
            return x.label == 'internal';
          }).value,
          resp.data.Records.ownership_realization.find((x) => {
            return x.label == 'mix';
          }).value,
        ];
        this.total_target = resp.data.Records.total_target;
        this.goal_completion = resp.data.Records.goal_completion;
        this.ongoing_target = resp.data.Records.ongoing_target;
        this.special_total_target = resp.data.Records.special_total_target;
        this.special_goal_completion =
          resp.data.Records.special_goal_completion;
        this.special_ongoing_target = resp.data.Records.special_ongoing_target;
      });
    },
    getTerritories() {
      this.$http.get('/api/report/v1/master/territory').then((resp) => {
        this.optionTerritories = resp.data.records;
      });
    },
    getRegions() {
      this.$http.get('/api/report/v1/dashboard/regions').then((resp) => {
        this.optionRegions = resp.data.Records;
      });
    },
    getOUs() {
      this.$http.get('/api/report/v1/master/ou').then((resp) => {
        this.optionOUs = resp.data.records;
      });
    },
    getPromotionTypes() {
      this.$http
        .get('/api/report/v1/dashboard/promotion-types')
        .then((resp) => {
          this.optionPromotionTypes = resp.data.Records;
        });
    },
    getBudgetCodes() {
      this.$http.get('/api/report/v1/dashboard/budget-codes').then((resp) => {
        this.optionBudgetCodes = resp.data.Records;
      });
    },
    getOwnerships() {
      this.$http.get('/api/report/v1/dashboard/ownerships').then((resp) => {
        this.optionOwnerships = resp.data.Records;
      });
    },
  },
  mounted() {
    this.getRegions();
    this.getOUs();
    this.getPromotionTypes();
    this.getBudgetCodes();
    this.getOwnerships();
    this.getDashboardData();
    this.getTerritories();
  },
};
</script>
